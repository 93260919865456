import dayjs from 'dayjs'

export const formatDate = (stringDate: string, format = 'DD-MM-YYYY') =>
  dayjs(stringDate).format(format).toString()

export const createDateFromString = (stringDate: string) => dayjs(stringDate)

export const getDaysAgoFromADate = (stringDate: string) => dayjs().diff(dayjs(stringDate), 'days')

/**
 *
 * @param array -> Any array of any type
 * @returns -> A boolean that indicates if the array only contains string elements
 */
export const isArrayOfStrings = (array: any[]) =>
  array.every((arrayItem) => typeof arrayItem === 'string')

export const navigateToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}
