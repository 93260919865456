import { createSlice } from '@reduxjs/toolkit'
import StatusE from '../../types/statusE'
import initialSettingsState from './state'

const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialSettingsState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setError: (state, action) => {
      state.status = StatusE.IDLE
      state.error = action.payload
    },
    setUser: (state, action) => {
      state.status = StatusE.IDLE
      state.user = action.payload
    },
  },
})

export const { setStatus, setError, setUser } = settingsSlice.actions

export default settingsSlice.reducer
