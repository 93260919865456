import { GlobalToken } from 'antd'

const siderStyles = (theme: GlobalToken) => ({
  root: {
    backgroundColor: theme.colorBgBase,
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 200,
  },
  logo: {
    padding: 8,
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: 50,
    width: '100%',
  },
  menuItem: {
    alignItems: 'center',
    gap: 8,
  },
})

export default siderStyles
