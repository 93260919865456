import { Breakpoint, GlobalToken } from 'antd'

const styles = (theme: GlobalToken, screens: Partial<Record<Breakpoint, boolean>>) => ({
  root: {
    justifyContent: 'center',
  },
  container: {
    padding: `0 ${!screens.md ? '0px' : '50px'}`,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  cardContainer: {
    border: 1,
    borderStyle: 'solid',
    borderColor: theme.colorBorderSecondary,
  },
  cardHeaderContainer: {
    width: '100%',
    backgroundColor: theme.colorBgSpotlight,
    padding: '8px 16px',
  },
  cardHeaderSecondaryContainer: {
    width: '100%',
    padding: '8px 0px',
    gap: 8,
  },
  cardHeadertitle: {
    margin: 0,
    color: theme.colorWhite,
  },
  cardBodyRow: {
    margin: 16,
    gap: !screens.md ? 8 : 16,
  },
  cardBodyColumn: {
    textAlign: 'end',
  },
  cardHeaderSecondarytitle: {
    margin: 0,
  },
  cardHeaderSecondarytitleBadge: {
    backgroundColor: theme.colorPrimary,
  },
  cardBodyContainer: { width: '100%', padding: screens.xs ? 0 : 8 },
  personContainer: {
    padding: 18,
  },
  personContainerItem: {
    padding: !screens.md ? 0 : 16,
    width: '100%',
  },
  personContainerImages: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    padding: !screens.md ? 16 : 0,
    alignItems: 'center',
  },

  tagPersonType: {
    backgroundColor: theme.colorBgSpotlight,
    color: theme.colorWhite,
    padding: '4px 8px',
    fontSize: theme.fontSizeLG,
  },
  tagOffenceType: {
    backgroundColor: theme.colorPrimary,
    color: theme.colorWhite,
    padding: '4px 8px',
    fontSize: theme.fontSizeLG,
  },
})

export default styles
