import React from 'react'
import { IncidentT } from '../../../types/incidentT'
import { MatchT } from '../../../types/matchT'

const getProperty = (obj: { [key: string]: any }, path: string) => {
  if (typeof obj !== 'object' || obj === null) {
    return null
  }

  const keys = path.split('.')

  const result = keys.reduce((current, key) => {
    if (Array.isArray(obj) && !Number.isNaN(key)) {
      return current[parseInt(key, 10)]
    }
    return current ? current[key] : null
  }, obj)

  return result
}

export const isHighlightedValue = (incident: IncidentT, matches?: MatchT[], nodeValue?: string) => {
  if (matches) {
    const matchFound = matches.some((match) => {
      const result = getProperty(incident, match.path)
      return String(result) === nodeValue
    })

    return matchFound ? <mark>{nodeValue}</mark> : nodeValue
  }

  return false
}

export const toUppercase = (value: string) =>
  value
    .split('_')
    .reduce((current, next) => current.concat(' ').concat(next), '')
    .toUpperCase()
