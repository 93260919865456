import { GlobalToken } from 'antd'

const cardStyles = (theme: GlobalToken, isMobileOrTablet?: boolean) => ({
  root: {
    margin: 16,
    border: 1,
    borderStyle: 'solid',
    borderColor: theme.colorBorderSecondary,
    borderRadius: 6,
    width: '100%',
  },
  container: {
    flexDirection: isMobileOrTablet ? 'column' : 'row',
  },
  skeletonContainer: {
    padding: 8,
  },
  imageContainer: {
    borderBottomLeftRadius: isMobileOrTablet ? 0 : 6,
    borderTopLeftRadius: 6,
    borderTopRightRadius: isMobileOrTablet ? 6 : 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  image: { height: 200 },
  body: {
    padding: '12px 24px',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  bodyHeader: {
    alignItems: 'center',
  },
  headerTitle: {
    margin: 0,
  },
  bodyContent: {
    gap: isMobileOrTablet ? 8 : 0,
  },
  bodyContentTitle: {
    fontSize: 12,
  },
  associateTitle: {
    fontSize: 12,
  },
  footerTitle: {
    fontSize: 12,
  },
  matchesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  matchContainer: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
  },
  highlight: {
    backgroundColor: ' #ffcfbe',
    paddingInline: 5,
    borderRadius: 5,
  },
})

export default cardStyles
