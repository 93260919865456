import { AxiosError } from 'axios'
import { store } from '../../app/store'
import APIClient from '../../apiClient'
import StatusE from '../../types/statusE'
import { setError, setUser, setStatus } from './slice'
import { ErrorT } from '../../types/errorT'

const apiClient = new APIClient(true, undefined, process.env.REACT_APP_WORDPRESS_API_URL)
const apiInstance = apiClient.getInstance()

export const getWordpressUser = () => (dispatch: typeof store.dispatch) => {
  dispatch(setStatus(StatusE.LOADING))

  apiInstance
    .get('/wp-json/wp/v2/users/me')
    .then((response) => {
      if (response.data) {
        apiInstance.get(`/wp-json/mp/v1/members/${response.data.id}`).then((user) => {
          dispatch(setUser(user.data))
        })
      }
    })
    .catch((error: ErrorT | AxiosError) => {
      if (error instanceof AxiosError) {
        const { status, name, message } = error
        dispatch(setError({ status, name, message }))
      } else {
        dispatch(setError(error))
      }
    })
}

export const updateWordpressUser =
  (userId: number, data: any) => (dispatch: typeof store.dispatch) => {
    dispatch(setStatus(StatusE.LOADING))
    apiInstance
      .post(`/wp-json/mp/v1/members/${userId}`, data)
      .then(() => {
        dispatch(setStatus(StatusE.CREATED))
      })
      .catch((error: ErrorT | AxiosError) => {
        if (error instanceof AxiosError) {
          const { status, name, message } = error
          dispatch(setError({ status, name, message }))
        } else {
          dispatch(setError(error))
        }
      })
  }
