const styles = () => ({
  root: {
    justifyContent: 'center',
  },
  title: {
    marginTop: 0,
  },
})

export default styles
