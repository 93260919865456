import { GlobalToken } from 'antd'

const loginStyles = (theme: GlobalToken) => ({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    backgroundColor: theme.colorWhite,
    padding: 32,
    borderRadius: 16,
    margin: 8,
  },
})

export default loginStyles
