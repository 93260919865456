const settingsStyles = () => ({
  root: {
    justifyContent: 'center',
  },
  title: {
    marginTop: 0,
  },
  container: {
    width: '100%',
  },
})

export default settingsStyles
