import { GlobalToken } from 'antd'

const styles = (theme: GlobalToken) => ({
  root: {
    marginBottom: 0,
  },
  formItemDatePicker: {
    width: '100%',
  },
  formItemSelectDropdownContent: {
    alignItems: 'center',
    gap: 8,
    padding: 8,
  },
  tagContainer: {
    border: 0,
    backgroundColor: theme.colorFill,
    display: 'flex',
    alignItems: 'center',
  },
  tagIcon: {
    fontSize: 14,
    color: theme.colorWhite,
    marginTop: 6,
  },
  formItemDescription: {
    paddingBottom: 24
  },
})

export default styles
