import { GlobalToken } from 'antd'

const headerStyles = (theme: GlobalToken, isMobileOrTablet?: boolean) => ({
  root: {
    backgroundColor: theme.colorBgBase,
    paddingInline: 0,
  },
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  logo: {
    display: isMobileOrTablet ? 'block' : 'none',
    maxWidth: 150,
  },
  iconButton: {
    marginTop: 4,
  },
})

export default headerStyles
