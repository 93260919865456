/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from 'react'
import { Button, Form, Input } from 'antd'
import { BusinessUserT } from '../../../../types/businessUserT'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { updateWordpressUser } from '../../actions'

export default function BusinessAccount() {
  const [form] = Form.useForm<BusinessUserT & { fullAddress: string }>()
  const { user } = useAppSelector((state) => state.account)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (user && user.profile) {
      const {
        profile: {
          mepr_facility_name,
          mepr_business_name_if_different_from_facility_name,
          mepr_abn_australian_business_number,
          mepr_website_link,
          mepr_phone,
        },
      } = user
      form.setFieldsValue({
        mepr_facility_name,
        mepr_business_name_if_different_from_facility_name,
        mepr_abn_australian_business_number,
        mepr_website_link,
        mepr_phone,
      })
    }
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = (values: BusinessUserT) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (user) {
      dispatch(updateWordpressUser(user.id, values))
    }
  }

  return (
    <Form
      layout="vertical"
      form={form}
      scrollToFirstError
      size="large"
      onFinish={onFinish}
      onFieldsChange={() => {}}
    >
      <Form.Item name="mepr_facility_name" label="Property Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="mepr_business_name_if_different_from_facility_name"
        label="Business Name"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="mepr_abn_australian_business_number"
        label="Business Registration Number (ie: ABN Australia)"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="mepr_website_link" label="Website Link" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="mepr_phone" label="Phone" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
        <Button type="primary" htmlType="submit">
          Save changes
        </Button>
      </Form.Item>
    </Form>
  )
}
