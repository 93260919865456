import { GlobalToken } from 'antd'
import { CSSProperties } from 'react'

const dashboardStyles = (
  theme: GlobalToken,
  isMobileOrTablet?: boolean
): { [key: string]: CSSProperties } => ({
  root: {
    padding: `0 ${isMobileOrTablet ? '0px' : '50px'}`,
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  checkImage: {
    width: 150,
  },
  defaultContainer: {
    justifyContent: 'center',
  },
  checkButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
  },
  defaultTextContainer: {
    textAlign: 'center',
  },
  incidentsContainer: {
    padding: `8px 0px`,
  },
  kioskTitle: {
    alignItems: 'center',
    gap: 8,
  },
  loader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputContainerLink: {
    alignSelf: 'flex-end',
  },
})

export default dashboardStyles
