import { createSlice } from '@reduxjs/toolkit'
import StatusE from '../../types/statusE'
import initialIncidentState from './state'

const incidentSlice = createSlice({
  name: 'incident',
  initialState: initialIncidentState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
      state.status = StatusE.IDLE
    },
    setResponse: (state, action) => {
      state.response = action.payload
      state.status = StatusE.CREATED
      state.error = undefined
    },
    setIncidentDetail: (state, action) => {
      state.status = StatusE.IDLE
      state.incidentDetail = action.payload
    },
  },
})

export const { setStatus, setError, setResponse, setIncidentDetail } = incidentSlice.actions

export default incidentSlice.reducer
