const siderItemStyles = (isMobileOrTablet: boolean) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: 50,
    width: '100%',
  },

  menuContainer: {
    width: '100%',
  },
  menuDropdownContainer: {
    display: isMobileOrTablet ? 'none' : 'block',
    position: 'fixed',
    bottom: isMobileOrTablet ? 20 : 40,
    width: isMobileOrTablet ? 240 : 200,
  },
  menuItem: {
    marginTop: 8,
  },
  menuIconItem: {
    alignItems: 'center',
    gap: 4,
  },
  menuItemBottom: {
    fontWeight: 600,
    fontSize: 16,
  },
})

export default siderItemStyles
