import React from 'react'
import { Col, Image, Menu, MenuProps, Row, Typography, theme } from 'antd'
import { BiArrowBack } from 'react-icons/bi'
import siderStyles from './styles'
import logo from '../../assets/images/logo.svg'
import { useAppSelector } from '../../app/hooks'
import { GTOB_BACKURL } from '../../constants'

export default function KioskModeSider() {
  const { token } = theme.useToken()
  const styles = siderStyles(token)
  const { backurl } = useAppSelector((state) => state.login)

  const getBackurl = () => localStorage.getItem(GTOB_BACKURL) ?? backurl

  const items: MenuProps['items'] = [
    {
      key: 'check_guest',
      label: (
        <Row style={styles.menuItem}>
          <BiArrowBack size={22} />
          <a href={getBackurl()}>
            <Typography.Title level={5} style={{ marginTop: 8 }}>
              Back to Mews
            </Typography.Title>
          </a>
        </Row>
      ),
    },
  ]

  const logoImage = <Image src={logo} style={{ ...styles.logo, width: 200 }} preview={false} />

  return (
    <Row>
      <Col>
        {logoImage}
        <Row style={styles.menuContainer as React.CSSProperties}>
          <Col>
            <Menu style={{ width: '100%' }} items={items} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
