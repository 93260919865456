const styles = () => ({
  acknowledgeContent: {
    textAlign: 'center',
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
})

export default styles
