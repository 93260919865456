import { combineReducers } from '@reduxjs/toolkit'
import loginReducers from '../pages/login/slice'
import dashboardReducers from '../pages/guest/slice'
import drawerReducers from '../components/drawer/slice'
import incidentReducers from '../pages/incident/slice'
import siderReducers from '../components/sider/slice'
import accountReducers from '../pages/account/slice'

const rootReducers = combineReducers({
  login: loginReducers,
  dashboard: dashboardReducers,
  drawer: drawerReducers,
  incident: incidentReducers,
  sider: siderReducers,
  account: accountReducers,
})

export default rootReducers
