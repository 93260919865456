import React from 'react'
import { Col, DatePicker, Form, Input, Row, Select, Tag, Typography, theme } from 'antd'
import dayjs from 'dayjs'
import { RangePickerProps } from 'antd/es/date-picker'
import { AiOutlineClose, AiOutlineInfoCircle } from 'react-icons/ai'
import { DATE_TIME_FORMAT, INPUT_DATA_OFFENCES } from '../../../../../../../constants'
import getStyles from './styles'

export default function IncidentDetailsFormSection() {
  const { token } = theme.useToken()
  const styles = getStyles(token)
  const disabledDate: RangePickerProps['disabledDate'] = (current) =>
    current && current > dayjs().endOf('day')

  return (
    <Form.Item
      label={<Typography.Title level={5}>Incident Details</Typography.Title>}
      style={styles.root}
    >
      <Row justify="space-between" wrap>
        <Col span={24}>
          <Form.Item label="Date and time" name="datetime" rules={[{ required: true }]}>
            <DatePicker
              format={DATE_TIME_FORMAT}
              showTime={{ format: 'HH:mm a' }}
              disabledDate={disabledDate}
              placeholder="Select date and time"
              style={styles.formItemDatePicker}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="description"
        label="Description"
        help="Use this field to provide details of the incident, which will be used for administrative purposes only. To protect guest privacy, it will not be shown when users view or search the report."
        style={styles.formItemDescription}
      >
        <Input.TextArea
          autoSize={{ minRows: 4 }}
          placeholder="A short description of the incident"
        />
      </Form.Item>
      <Form.Item name="offences" label="Offences">
        <Select
          mode="multiple"
          options={INPUT_DATA_OFFENCES}
          maxTagCount="responsive"
          // eslint-disable-next-line
          dropdownRender={(originNode) => (
            <Row>
              <Col span={24}>
                <Row style={styles.formItemSelectDropdownContent}>
                  <Typography.Text type="secondary">You may choose more than one</Typography.Text>
                  <AiOutlineInfoCircle />
                </Row>

                {originNode}
              </Col>
            </Row>
          )}
          // eslint-disable-next-line
          tagRender={({ label, closable, onClose }) => (
            <Tag
              closable={closable}
              onClose={onClose}
              style={styles.tagContainer}
              closeIcon={<AiOutlineClose style={styles.tagIcon} />}
              onMouseDown={(event) => {
                event.preventDefault()
                event.stopPropagation()
              }}
            >
              <Typography.Text>{label}</Typography.Text>
            </Tag>
          )}
        />
      </Form.Item>
    </Form.Item>
  )
}
