const layoutStyles = (isMobileOrTablet: boolean) => ({
  root: {
    padding: isMobileOrTablet ? 25 : '30px 50px',
  },
  container: {
    marginTop: 50,
  },
})

export default layoutStyles
