import StatusE from '../../types/statusE'
import { LoginState } from './stateI'

const initialLoginState: LoginState = {
  status: StatusE.IDLE,
  isLoggedIn: false,
  requestedLogout: false,
}

export default initialLoginState
