import React from 'react'
import { Col, Form, Radio, Row, Space, Typography, theme } from 'antd'
import { FaUserTimes } from 'react-icons/fa'
import { BsPeopleFill } from 'react-icons/bs'
import getStyles from '../styles'

export default function DetailPersonTypePeopleFormSection({
  name,
  type,
  setType,
}: {
  name: number
  type: string
  setType: React.Dispatch<React.SetStateAction<string>>
}) {
  const { token } = theme.useToken()
  const styles = getStyles(token)

  return (
    <Form.Item
      name={[name, 'type']}
      label="Offender or associate"
      rules={[{ required: true, message: 'Please select an option' }]}
      initialValue={type}
    >
      <Row justify="space-between" wrap gutter={[12, 12]}>
        <Col xs={24} sm={12}>
          <Radio.Button
            value="offender"
            style={styles.formItemRadioPanelContainer}
            onChange={(event) => setType(event.target.value)}
            checked={type === 'offender'}
          >
            <Space style={styles.formItemRadioPanel}>
              <Typography.Text>Offender</Typography.Text>
              <FaUserTimes />
            </Space>
            <Typography.Paragraph type="secondary">
              The person directly involved in causing an offence
            </Typography.Paragraph>
          </Radio.Button>
        </Col>
        <Col xs={24} sm={12}>
          <Radio.Button
            value="associate"
            style={styles.formItemRadioPanelContainer}
            onChange={(event) => setType(event.target.value)}
            checked={type === 'associate'}
          >
            <Space style={styles.formItemRadioPanel}>
              <Typography.Text>Associate</Typography.Text>
              <BsPeopleFill />
            </Space>
            <Typography.Paragraph type="secondary">
              A person travelling or staying with others involved in the incident
            </Typography.Paragraph>
          </Radio.Button>
        </Col>
      </Row>
    </Form.Item>
  )
}
