import { GlobalToken } from 'antd'

const styles = (theme: GlobalToken) => ({
  defaultLink: {
    fontSize: 10,
    color: theme.colorBgSpotlight,
    textDecoration: 'underline',
  },
  formItemLabelContainer: { display: 'flex', alignItems: 'center', gap: 8 },
  formItemSelectNotFoundContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  formItemSelectDropDownContent: { alignItems: 'center', gap: 8, padding: 8 },
})

export default styles
