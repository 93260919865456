import React from 'react'
import { Tag, Typography, theme } from 'antd'
import { AiOutlineClose } from 'react-icons/ai'
import getStyles from '../styles'

export default function AliasesTag({
  renderClosable,
  renderOnClose,
  renderLabel,
}: {
  renderClosable: boolean
  renderOnClose: () => void
  renderLabel: React.ReactNode
}) {
  const { token } = theme.useToken()
  const styles = getStyles(token)

  return (
    <Tag
      closable={renderClosable}
      onClose={renderOnClose}
      style={styles.tagContainer}
      closeIcon={<AiOutlineClose style={styles.tagIcon} />}
      onMouseDown={(event) => {
        event.preventDefault()
        event.stopPropagation()
      }}
    >
      <Typography.Text>{renderLabel}</Typography.Text>
    </Tag>
  )
}
