import React from 'react'
import { Col, Row } from 'antd'
import PersonalAccountForm from './components/form'

export default function PersonalAccount() {
  return (
    <Row>
      <Col span={24}>
        <PersonalAccountForm />
      </Col>
    </Row>
  )
}
