import React, { useEffect } from 'react'
import { Col, Row, Spin, Tabs, TabsProps, Typography, notification } from 'antd'
import settingsStyles from './styles'
import PersonalAccount from './components/personal'
import BusinessAccount from './components/business'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { getWordpressUser } from './actions'
import Subscriptions from './components/subscriptions'
import Payments from './components/payments'
import StatusE from '../../types/statusE'
import { setStatus } from './slice'

export default function Account() {
  const styles = settingsStyles()
  const dispatch = useAppDispatch()
  const { status } = useAppSelector((state) => state.account)

  const [api, contextHolder] = notification.useNotification()

  const items: TabsProps['items'] = [
    {
      key: 'personal',
      label: `Personal`,
      children: <PersonalAccount />,
    },
    {
      key: 'business',
      label: `Business`,
      children: <BusinessAccount />,
    },
    {
      key: 'subscriptions',
      label: `Subscriptions`,
      children: <Subscriptions />,
    },
    {
      key: 'payments',
      label: `Payments`,
      children: <Payments />,
    },
  ]

  useEffect(() => {
    dispatch(getWordpressUser())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (status === StatusE.CREATED) {
      api.success({
        message: 'Changes saved successfully',
        duration: 2,
        onClose: () => dispatch(setStatus(StatusE.IDLE)),
      })
    }
    if (status === StatusE.ERROR) {
      api.success({
        message: 'Something went wrong, please try it again',
        duration: 2,
        onClose: () => dispatch(setStatus(StatusE.IDLE)),
      })
    }
  }, [status]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Row style={styles.root}>
      {contextHolder}
      <Col xs={20} md={18} lg={18} xl={14}>
        <Typography.Title level={3} style={styles.title}>
          Account
        </Typography.Title>
        <Row>
          <Col style={styles.container}>
            <Spin spinning={status === StatusE.LOADING}>
              <Tabs defaultActiveKey="account" items={items} />
            </Spin>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
