/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react'
import { Button, Col, Form, Grid, Image, Input, Row, Spin, Typography, theme } from 'antd'
import { BsShieldFillCheck, BsShieldFillExclamation, BsSearch } from 'react-icons/bs'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import StatusE from '../../../types/statusE'
import { getIncidents } from '../actions'
import dashboardStyles from './styles'
import check from '../../../assets/images/check.svg'
import IncidentCard from './components/incidentCard'
import { setQuery } from '../slice'
import { navigateToTop } from '../../../utils'
import { setBackurl, setKioskMode } from '../../login/slice'
import { GTOB_BACKURL } from '../../../constants'

export default function GuestCheck() {
  const { token } = theme.useToken()
  const screens = Grid.useBreakpoint()
  const styles = dashboardStyles(token, !screens.md)
  const dispatch = useAppDispatch()
  const { status, incidents, query, isFirstRender } = useAppSelector((state) => state.dashboard)
  const [searchParams] = useSearchParams()
  const kioskParam = searchParams.get('kiosk') || searchParams.get('amp;kiosk')
  const backurlParam = searchParams.get('backurl') || searchParams.get('amp;backurl')
  const location = useLocation()
  const { kioskMode } = useAppSelector((state) => state.login)

  useEffect(() => {
    navigateToTop()
  }, [])

  useEffect(() => {
    dispatch(setKioskMode(kioskParam))
  }, [kioskParam])

  useEffect(() => {
    const backurl = localStorage.getItem(GTOB_BACKURL)
    if (!backurl) {
      localStorage.setItem(GTOB_BACKURL, backurlParam ?? '')
      dispatch(setBackurl(backurlParam))
    }
  }, [backurlParam])

  const isValidQuery = useCallback(() => /^[^\s]+(\s+[^\s]+)*$/.test(query), [query])

  const onHandleIncidents = useCallback(() => {
    if (isValidQuery()) {
      dispatch(getIncidents(query))
    }
  }, [dispatch, query, isValidQuery])

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const guest = urlParams.get('guest')
    if (guest) {
      dispatch(setQuery(guest))
      onHandleIncidents()
    }
  }, [location, onHandleIncidents])

  const renderIncidents = () => {
    if (kioskMode && status === StatusE.LOADING)
      return <Spin tip="Loading" size="large" style={styles.loader} />
    if (isFirstRender) return null
    return incidents.length > 0 ? (
      incidents.map((incident) => <IncidentCard key={incident.id} incident={incident} />)
    ) : (
      <Row style={styles.defaultContainer}>
        <Col>
          <Typography.Paragraph>No match. You are good to book</Typography.Paragraph>
        </Col>
      </Row>
    )
  }

  const renderInputIcon = () => {
    if (isFirstRender || status === StatusE.LOADING) return null
    return incidents.length > 0 ? (
      <BsShieldFillExclamation color={token.colorPrimary} />
    ) : (
      <BsShieldFillCheck color={token.colorSuccess} />
    )
  }

  const renderMatchesFoundText = () => {
    if (isFirstRender || status === StatusE.LOADING) return null
    if (incidents.length > 0)
      return kioskMode ? (
        <Row>
          <Col>
            <Image preview={false} src={check} style={styles.checkImage} />
            <Row>
              <Typography.Paragraph>
                Stay aware of reported guest incidents and related issues before booking guests.
              </Typography.Paragraph>
            </Row>
            <Row>
              <Typography.Title level={3}>Searching for guest {query} - [Mews]</Typography.Title>
            </Row>
            <Row style={styles.kioskTitle}>
              <Typography.Title level={5} style={{ margin: 0 }}>
                Potential matches found
              </Typography.Title>
              <BsShieldFillExclamation />
            </Row>
          </Col>
        </Row>
      ) : (
        <Typography.Title level={5} style={styles.defaultTextContainer}>
          Matches found. Check the details below before booking this guest
        </Typography.Title>
      )
    return null
  }

  return (
    <Row style={styles.root}>
      <Col xs={24} sm={24} xl={20} xxl={16} pull={screens.xs || screens.sm ? 0 : 3}>
        <Row style={styles.defaultContainer}>
          <Col
            xs={kioskMode ? 24 : 20}
            sm={kioskMode ? 24 : 22}
            lg={kioskMode ? 22 : 20}
            xl={kioskMode ? 23 : 18}
            style={styles.container}
          >
            {kioskMode ? null : (
              <Row>
                <Col xs={24} sm={24} lg={20} xl={18}>
                  <Image preview={false} src={check} style={styles.checkImage} />
                </Col>
              </Row>
            )}
            <Row style={styles.defaultContainer}>
              {kioskMode ? null : (
                <Col span={24}>
                  <Typography.Title level={3}>Search Guest Details</Typography.Title>
                  <Typography.Paragraph>
                    Stay aware of reported guest incidents and related issues before booking guests
                  </Typography.Paragraph>
                  <div style={styles.inputContainer}>
                    <Form.Item>
                      <Input.Search
                        size="large"
                        placeholder="Enter guest's name, ID, phone number or vehicle registration"
                        value={query}
                        onChange={(e) => dispatch(setQuery(e.currentTarget.value))}
                        suffix={renderInputIcon()}
                        enterButton={
                          <Button
                            type="primary"
                            icon={<BsSearch />}
                            loading={status === StatusE.LOADING}
                            style={styles.checkButton}
                            disabled={!isValidQuery()}
                          >
                            Check
                          </Button>
                        }
                        onSearch={onHandleIncidents}
                      />
                    </Form.Item>
                  </div>
                </Col>
              )}
              <Col xs={{ span: 24 }} sm={{ span: kioskMode ? 24 : 12 }}>
                {renderMatchesFoundText()}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={styles.incidentsContainer}>
            {renderIncidents()}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
