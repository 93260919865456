import { DefaultOptionType } from 'antd/es/select'

export const GTOB_TOKEN_IDENTIFIER = 'gtob-token'
export const GTOB_TOKEN_EXPIRATION_IDENTIFIER = 'gtob-token-expiration'
export const GTOB_TOKEN_LONG_EXPIRATION_IDENTIFIER = 'gtob-token-long-expiration'
export const LONG_EXPIRATION_TIME = 2592000
export const DEFAULT_ERROR_MESSAGE = 'Oops, something happened'
export const DEFAULT_SUCCESS_MESSAGE = 'Well done!'
export const LOGIN_SCOPES = 'incident:read%20incident:write'
export const INPUT_DATA_OFFENCES: DefaultOptionType[] = [
  { value: 'agreed_rules_broken', label: 'Agreed rules broken' },
  { value: 'credit_card_fraud', label: 'Credit card fraud' },
  { value: 'excessive_mess', label: 'Excessive mess' },
  { value: 'fraud', label: 'Fraud' },
  { value: 'illicit_substance', label: 'Illicit substance' },
  { value: 'malicious_chargeback', label: 'Malicious chargeback' },
  { value: 'outstanding_fees', label: 'Outstanding fees' },
  { value: 'police_callout', label: 'Police callout' },
  { value: 'police_incident_filed', label: 'Police incident filed' },
  { value: 'threatening_behaviour', label: 'Threatening behaviour' },
  { value: 'theft', label: 'Theft' },
  { value: 'violence', label: 'Violence' },
  { value: 'wilful_damage', label: 'Wilful damage' },
]
export const INPUT_DATA_COUNTRIES: DefaultOptionType[] = [
  { value: 'AU', label: 'Australia' },
  { value: 'NZL', label: 'New Zealand' },
]
export const INPUT_DATA_STATES: DefaultOptionType[] = [
  { value: 'QLD', label: 'Queensland' },
  { value: 'NSW', label: 'New South Wales' },
]
export const INPUT_DATA_COLOURS: DefaultOptionType[] = [
  { value: 'black', label: 'Black' },
  { value: 'blue', label: 'Blue' },
  { value: 'brown', label: 'Brown' },
  { value: 'green', label: 'Green' },
  { value: 'grey', label: 'Grey' },
  { value: 'orange', label: 'Orange' },
  { value: 'purple', label: 'Purple' },
  { value: 'red', label: 'Red' },
  { value: 'silver', label: 'Silver' },
  { value: 'white', label: 'White' },
  { value: 'yellow', label: 'Yellow' },
]
export const INPUT_DATA_GENDERS: DefaultOptionType[] = [
  { value: 'female', label: 'Female' },
  { value: 'male', label: 'Male' },
  { value: 'unknown', label: 'Unknown' },
]
export const INPUT_DATA_IDENTIFICATION_TYPES: DefaultOptionType[] = [
  {
    value: 'photo_id_card',
    label: 'Card',
  },
  {
    value: 'driver_license',
    label: 'Driver License',
  },
  {
    value: 'passport',
    label: 'Passport',
  },
]
export const DATE_INPUT_KEYS = ['birth_date']

export const GTOB_URL = 'https://goodtobook.com/'
export const GTOB_RESOURCES_URL = `${GTOB_URL}member-resources`
export const GTOB_ACCOUNT_URL = `${process.env.REACT_APP_WORDPRESS_API_URL}/account/`
export const GTOB_BACKURL = 'gtob-backurl'
export const DATE_TIME_FORMAT = 'DD MMM YYYY hh:mm a'
