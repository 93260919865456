import React from 'react'
import { Modal, Typography } from 'antd'

export default function ConfirmDialog({
  showPrompt,
  confirmNavigation,
  cancelNavigation,
}: {
  showPrompt: boolean
  confirmNavigation: any
  cancelNavigation: any
}) {
  return (
    <Modal
      open={showPrompt}
      title="Leaving Page"
      onOk={confirmNavigation}
      onCancel={cancelNavigation}
      centered
    >
      <Typography>
        Unsafe information will be delete, Are you sure that you want to leave the page?
      </Typography>
    </Modal>
  )
}
