import React, { useEffect, useState } from 'react'
import { Col, Form, Input, Row, Select } from 'antd'
import { INPUT_DATA_IDENTIFICATION_TYPES } from '../../../../../../../../../../constants'

export default function DetailIdentificationPeopleFormSection({ name }: { name: number }) {
  const [required, setRequired] = useState(false)
  const identificationType = Form.useWatch(['people', name, 'identification', 'type'])
  const identificationIssuer = Form.useWatch(['people', name, 'identification', 'issuer'])
  const identificationNumber = Form.useWatch(['people', name, 'identification', 'number'])

  useEffect(() => {
    if (identificationType || identificationIssuer || identificationNumber) {
      setRequired(true)
    }
  }, [identificationType, identificationIssuer, identificationNumber])

  return (
    <Row justify="space-between" wrap gutter={12}>
      <Col xs={24} sm={8}>
        <Form.Item
          name={[name, 'identification', 'type']}
          label="ID Type"
          rules={[
            {
              required,
            },
          ]}
        >
          <Select options={INPUT_DATA_IDENTIFICATION_TYPES} />
        </Form.Item>
      </Col>
      <Col xs={24} sm={8}>
        <Form.Item
          name={[name, 'identification', 'issuer']}
          label="Issuer"
          rules={[
            {
              required,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} sm={8}>
        <Form.Item
          name={[name, 'identification', 'number']}
          label="ID Number"
          rules={[
            {
              required,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  )
}
