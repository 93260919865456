import React from 'react'
import { Col, Row, Typography, theme } from 'antd'
import getStyles from '../styles'

export default function DropdownAddress({
  renderOriginNode,
  renderSetShowManualAddress,
}: {
  renderOriginNode: React.ReactElement
  renderSetShowManualAddress: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { token } = theme.useToken()
  const styles = getStyles(token)
  return (
    <Row>
      <Col span={24}>
        <Row style={styles.formItemSelectDropDownContent}>
          <Typography.Link
            style={styles.defaultLink}
            onClick={() => renderSetShowManualAddress(true)}
          >
            Could you not find the address?, enter it manually
          </Typography.Link>
        </Row>

        {renderOriginNode}
      </Col>
    </Row>
  )
}
