import React from 'react'
import { Card } from 'antd'
import { useAppSelector } from '../../../../app/hooks'
import { UserT } from '../../../../types/userT'
import { formatDate } from '../../../../utils'
import { PaymentUserT } from '../../../../types/paymentUserT'

export default function Payments() {
  const { user } = useAppSelector((state) => state.account)

  const renderSubscription = (payment: PaymentUserT) => {
    const membership = (user as UserT).active_memberships.find(
      (mb) => mb.id === Number(payment.membership)
    )
    return (
      <Card key={payment.id} title={`${membership?.title} (${payment.trans_num})`}>
        <p>Price: {payment.total}</p>
        <p>Created at: {formatDate(payment.created_at)}</p>
        <p>Status: {payment.status}</p>
      </Card>
    )
  }

  return user && user.recent_transactions ? (
    <>{(user as UserT).recent_transactions.map((transaction) => renderSubscription(transaction))}</>
  ) : (
    <p>You do not have payments yet.</p>
  )
}
