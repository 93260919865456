import { AddressUserT } from '../../../../../types/addressUserT'

const getValueFromGoogleAddressComponent = (label: string, components: any[]) => {
  const foundValue = components.find((component) => component.types.includes(label))
  if (foundValue) {
    return foundValue.short_name
  }
  return ''
}

const buildAddress = (placeDetails: any) => {
  const { address_components: addressComponent } = placeDetails
  const resultAddress: AddressUserT = {
    'mepr-address-one': getValueFromGoogleAddressComponent(
      'street_number',
      addressComponent
    ).concat(` ${getValueFromGoogleAddressComponent('route', addressComponent)}`),
    'mepr-address-city': getValueFromGoogleAddressComponent(
      'administrative_area_level_2',
      addressComponent
    ),
    'mepr-address-state': getValueFromGoogleAddressComponent(
      'administrative_area_level_1',
      addressComponent
    ),
    'mepr-address-zip': getValueFromGoogleAddressComponent('postal_code', addressComponent),
    'mepr-address-country': getValueFromGoogleAddressComponent('country', addressComponent),
  }

  return resultAddress
}

export default buildAddress
