import { createSlice } from '@reduxjs/toolkit'
import initialDashboardState from './state'

const siderSlice = createSlice({
  name: 'sider',
  initialState: initialDashboardState,
  reducers: {
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload
    },
  },
})

export const { setSelectedItem } = siderSlice.actions

export default siderSlice.reducer
