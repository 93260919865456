import { GlobalToken } from 'antd'

const styles = (theme: GlobalToken) => ({
  root: {
    justifyContent: 'space-between',
  },
  previewRoot: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    marginBottom: 8,
  },
  previewContainer: {
    width: '100%',
    alignItems: 'center',
  },
  previewContent: {
    display: 'flex',
    width: '100%',
    border: 1,
    borderColor: theme.colorFill,
    borderStyle: 'solid',
    gap: 8,
    borderRadius: 4,
  },
  previewDeleteButton: {
    backgroundColor: theme?.colorFill,
    minWidth: 19,
    width: 19,
    height: 19,
  },
  previewDeleteButtonIcon: {
    color: theme.colorWhite,
    size: 14,
  },
  previewImageContainer: {
    margin: 0,
  },
  previewImage: {
    width: 80,
    height: 40,
    objectFit: 'cover',
    objectPosition: 'top',
  },
  previewTypeContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  previewTypeLabel: {
    wordBreak: 'normal',
  },
  formItemContainerInputType: {
    margin: 0,
    width: '100%',
  },
  dndbaseStyle: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    borderWidth: 2,
    borderRadius: 4,
    borderColor: theme.colorFill,
    borderStyle: 'dotted',
    color: theme.colorFill,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
  },
  dndfocusedStyle: {
    borderColor: theme.colorPrimary,
  },
  dndacceptStyle: {
    borderColor: theme.colorSuccess,
  },
  dndrejectStyle: {
    borderColor: theme.colorError,
  },
})

export default styles
