import React from 'react'
import { Col, Grid, Row, Typography, theme } from 'antd'
import getStyles from './styles'
import { VehicleT } from '../../../../../types/vehicleT'

export default function IncidentDetailVehicle({ vehicles = [] }: { vehicles: VehicleT[] }) {
  const { token } = theme.useToken()
  const screens = Grid.useBreakpoint()
  const styles = getStyles(token, !screens.md)

  return (
    <Row wrap gutter={24}>
      {vehicles.map((vehicle) => (
        <Col span={12} key={vehicle.registration_number}>
          <Row style={styles.cardContainer as React.CSSProperties}>
            <Col
              style={{
                width: '100%',
              }}
            >
              <Row style={styles.cardBodyRow}>
                <Col xs={24} lg={7} xl={9} style={styles.cardBodyColumn as React.CSSProperties}>
                  <Typography.Text type="secondary">REGISTRATION</Typography.Text>
                </Col>
                <Col push={screens.xs ? 0 : 1} style={styles.label as React.CSSProperties}>
                  <Typography.Text>{vehicle.registration_number}</Typography.Text>
                </Col>
              </Row>
              <Row style={styles.cardBodyRow}>
                <Col xs={24} lg={7} xl={9} style={styles.cardBodyColumn as React.CSSProperties}>
                  <Typography.Text type="secondary">STATE</Typography.Text>
                </Col>
                <Col push={screens.xs ? 0 : 1} style={styles.label as React.CSSProperties}>
                  <Typography.Text>{vehicle.registration_state}</Typography.Text>
                </Col>
              </Row>
              <Row style={styles.cardBodyRow}>
                <Col xs={24} lg={7} xl={9} style={styles.cardBodyColumn as React.CSSProperties}>
                  <Typography.Text type="secondary">COLOUR</Typography.Text>
                </Col>
                <Col push={screens.xs ? 0 : 1} style={styles.label as React.CSSProperties}>
                  <Typography.Text>{vehicle.colour}</Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  )
}
