import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { GTOB_TOKEN_IDENTIFIER } from '../../constants'
import { setIsLoggedIn } from '../../pages/login/slice'

export default function Authenticator({ children }: { children: JSX.Element }) {
  const dispatch = useAppDispatch()
  const token = localStorage.getItem(GTOB_TOKEN_IDENTIFIER)
  const { isLoggedIn, nextURL } = useAppSelector((state) => state.login)

  useEffect(() => {
    if (token) {
      dispatch(setIsLoggedIn(true))
    }
  }, [token]) // eslint-disable-line react-hooks/exhaustive-deps

  const nextRender = () => {
    if (nextURL) {
      window.location.replace(nextURL)
    }
    return children
  }

  return isLoggedIn || token ? (
    nextRender()
  ) : (
    <Navigate to={`/login?next=${window.location.href}`} state={window.location.href} />
  )
}
