import { AxiosError } from 'axios'
import { store } from '../../app/store'
import * as Constants from '../../constants'
import APIClient from '../../apiClient'
import StatusE from '../../types/statusE'
import { TokenT } from '../../types/tokenT'
import { setError, setIsLoggedIn, setStatus } from './slice'
import { OAuthErrorT } from '../../types/oAuthErrorT'

const apiClient = new APIClient(false)
const apiInstance = apiClient.getInstance()

export default (authorizationCode: string) => (dispatch: typeof store.dispatch) => {
  dispatch(setStatus(StatusE.LOADING))

  if (authorizationCode) {
    const requestData = new URLSearchParams()
    requestData.append('grant_type', 'authorization_code')
    requestData.append('code', authorizationCode)
    requestData.append('client_id', process.env.REACT_APP_OAUTH_CLIENT_ID ?? '')
    apiInstance
      .post<TokenT>(`/oauth/token`, requestData)
      .then((response) => {
        localStorage.setItem(
          Constants.GTOB_TOKEN_EXPIRATION_IDENTIFIER,
          (new Date().getTime() + response.data.expires_in * 1000).toString()
        )
        localStorage.setItem(
          Constants.GTOB_TOKEN_LONG_EXPIRATION_IDENTIFIER,
          (new Date().getTime() + Constants.LONG_EXPIRATION_TIME * 1000).toString()
        )
        localStorage.setItem(Constants.GTOB_TOKEN_IDENTIFIER, JSON.stringify(response.data))
        dispatch(setIsLoggedIn(true))
      })
      .catch((error: AxiosError<OAuthErrorT>) => {
        dispatch(setError(error.response?.data))
      })
  }
}
