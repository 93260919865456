import React from 'react'
import { Button, Col, Form, Input, Row, Select, Tag, theme, Typography } from 'antd'
import { AiOutlineClose } from 'react-icons/ai'
import { INPUT_DATA_COLOURS } from '../../../../../../../constants'
import getstyles from './styles'

export default function IncidentVehiclesFormSection() {
  const { token } = theme.useToken()
  const styles = getstyles(token)
  const currentVehicles = Form.useWatch('vehicles')

  return (
    <Form.Item
      label={
        <Row style={styles.formItemRootLabelContainer}>
          <Col>
            <Typography.Title level={5}>Vehicles</Typography.Title>
          </Col>
          <Col>
            {currentVehicles && currentVehicles.length > 0 ? (
              <Tag style={styles.defaultTag as React.CSSProperties}>{currentVehicles.length}</Tag>
            ) : null}
          </Col>
        </Row>
      }
    >
      <Form.List name="vehicles">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Row key={key} justify="space-between">
                <Col xs={24} sm={7}>
                  <Form.Item
                    label="Registration"
                    {...restField}
                    name={[name, 'registration_number']}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={11} sm={7}>
                  <Form.Item label="State" {...restField} name={[name, 'registration_state']}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={11} sm={7}>
                  <Form.Item label="Colour" {...restField} name={[name, 'colour']}>
                    <Select options={INPUT_DATA_COLOURS} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={1} style={styles.deleteItemButtonContainer}>
                  <Button
                    style={styles.deleteItemButton}
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation()
                      remove(name)
                    }}
                    shape="circle"
                    icon={<AiOutlineClose {...styles.deleteItemIconButton} />}
                  />
                </Col>
              </Row>
            ))}
            <Form.Item style={styles.secondaryButtonContainer}>
              <Button
                className="secondary-button-custom"
                onClick={() => add()}
                style={styles.secondaryButton}
              >
                Add Vehicle
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  )
}
