import { GlobalToken } from 'antd'

const styles = (theme: GlobalToken) => ({
  root: {
    marginBottom: 0,
  },
  formItemRootLabelContainer: { alignItems: 'center', gap: 8, marginBottom: 8 },
  defaultTag: {
    backgroundColor: theme?.colorFill,
    color: theme?.colorWhite,
    borderRadius: 8,
    padding: 1,
    width: 30,
    heigth: 50,
    textAlign: 'center',
    fontSize: 14,
    marginTop: '1.25em',
  },
  secondaryButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 24,
  },
  secondaryButton: {
    backgroundColor: theme?.colorFill,
  },
})

export default styles
