import { GlobalToken } from 'antd'

const styles = (theme: GlobalToken) => ({
  deleteItemButton: {
    backgroundColor: theme?.colorFill,
    minWidth: 19,
    width: 19,
    height: 19,
  },
  deleteItemButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteItemIconButton: {
    color: theme.colorWhite,
    size: 14,
  },
  secondaryButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 24,
  },
  secondaryButton: {
    backgroundColor: theme.colorFill,
  },
  formItemRootLabelContainer: { alignItems: 'center', gap: 8, marginBottom: 8 },
  defaultTag: {
    backgroundColor: theme?.colorFill,
    color: theme?.colorWhite,
    borderRadius: 8,
    padding: 1,
    width: 30,
    heigth: 50,
    textAlign: 'center',
    fontSize: 14,
    marginTop: '1.25em',
  },
})

export default styles
