import { GlobalToken } from 'antd'

const styles = (theme: GlobalToken, isMobileOrTablet: boolean) => ({
  cardContainer: {
    border: 1,
    borderStyle: 'solid',
    borderColor: theme.colorBorderSecondary,
    padding: isMobileOrTablet ? 0 : '16px 0px',
  },
  cardBodyRow: {
    margin: 16,
    gap: isMobileOrTablet ? 8 : 16,
  },
  cardBodyColumn: {
    textAlign: isMobileOrTablet ? 'center' : 'end',
  },
  label: {
    width: isMobileOrTablet ? '100%' : 'auto',
    textAlign: isMobileOrTablet ? 'center' : 'start',
  },
})

export default styles
