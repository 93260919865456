import { createSlice } from '@reduxjs/toolkit'
import StatusE from '../../types/statusE'
import initialLoginState from './state'

const loginSlice = createSlice({
  name: 'login',
  initialState: initialLoginState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
      state.isLoggedIn = false
      state.status = StatusE.IDLE
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload
      state.status = StatusE.IDLE
      state.error = undefined
    },
    setRequestedLogout: (state, action) => {
      state.requestedLogout = action.payload
      state.isLoggedIn = false
    },
    setNextURL: (state, action) => {
      state.nextURL = action.payload
    },
    setKioskMode: (state, action) => {
      state.kioskMode = action.payload === 'true'
    },
    setBackurl: (state, action) => {
      state.backurl = action.payload
    },
  },
})

export const {
  setStatus,
  setError,
  setIsLoggedIn,
  setRequestedLogout,
  setNextURL,
  setKioskMode,
  setBackurl,
} = loginSlice.actions

export default loginSlice.reducer
