import axios from 'axios'
import * as Constants from '../constants'
import { TokenT } from '../types/tokenT'
import { store } from '../app/store'
import { setIsLoggedIn } from '../pages/login/slice'

export default class Token {
  static async getValue(): Promise<string | null> {
    const stringToken = localStorage.getItem(Constants.GTOB_TOKEN_IDENTIFIER)
    if (stringToken) {
      const tokenParsed = JSON.parse(stringToken) as TokenT
      if (Token.isValid(Constants.GTOB_TOKEN_EXPIRATION_IDENTIFIER)) {
        return tokenParsed.access_token
      }

      const newToken = await Token.refresh(tokenParsed)
      return newToken ? newToken.access_token : null
    }
    return null
  }

  static isValid(expirationDateIdentifier: string | undefined): boolean {
    const expirationDate = localStorage.getItem(expirationDateIdentifier ?? '')
    return expirationDate ? Number(expirationDate) > new Date().getTime() : false
  }

  private static async refresh(token: TokenT) {
    try {
      const requestData = new URLSearchParams()
      requestData.append('grant_type', 'refresh_token')
      requestData.append('client_id', process.env.REACT_APP_OAUTH_CLIENT_ID ?? '')
      requestData.append('refresh_token', token.refresh_token)
      const newToken = await axios<TokenT>({
        url: `${process.env.REACT_APP_API_URL ?? ''}/oauth/token`,
        method: 'post',
        data: requestData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      })

      localStorage.setItem(
        Constants.GTOB_TOKEN_EXPIRATION_IDENTIFIER,
        (new Date().getTime() + newToken.data.expires_in * 1000).toString()
      )
      localStorage.setItem(
        Constants.GTOB_TOKEN_LONG_EXPIRATION_IDENTIFIER,
        (new Date().getTime() + Constants.LONG_EXPIRATION_TIME * 1000).toString()
      )
      localStorage.setItem(Constants.GTOB_TOKEN_IDENTIFIER, JSON.stringify(newToken.data))
      return newToken.data
    } catch (error) {
      localStorage.removeItem(Constants.GTOB_TOKEN_IDENTIFIER)
      localStorage.removeItem(Constants.GTOB_TOKEN_EXPIRATION_IDENTIFIER)
      localStorage.removeItem(Constants.GTOB_TOKEN_LONG_EXPIRATION_IDENTIFIER)
      store.dispatch(setIsLoggedIn(false))
      return null
    }
  }
}
