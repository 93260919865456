import { DATE_INPUT_KEYS } from '../../../../../constants'
import { AddressT } from '../../../../../types/addressT'
import { IncidentT } from '../../../../../types/incidentT'
import { formatDate, isArrayOfStrings } from '../../../../../utils'

export const removeUndefinedValues = (values: any) =>
  Object.keys(values).reduce((acc: { [key: string]: any }, key: string) => {
    if (DATE_INPUT_KEYS.includes(key)) {
      if (values[key]) {
        acc[key] = formatDate(values[key], 'YYYY-MM-DD')
      }
      return acc
    }
    if (Array.isArray(values[key])) {
      if (!isArrayOfStrings(values[key])) {
        acc[key] = values[key].map(removeUndefinedValues)
      } else {
        acc[key] = values[key]
      }
      return acc
    }
    if (typeof values[key] === 'object') {
      acc[key] = removeUndefinedValues(values[key])
      if (Object.entries(acc[key]).length === 0) {
        delete acc[key]
      }
      return acc
    }
    if (values[key] === undefined || values[key] === '' || values[key] === null) {
      return acc
    }
    acc[key] = values[key]
    return acc
  }, {})

export const mapFormValuesToIncidentType = (values: IncidentT) => {
  const datetime = new Date(values.datetime).toISOString()
  const incident = removeUndefinedValues(values)

  return { ...incident, datetime } as IncidentT
}

const getValueFromGoogleAddressComponent = (label: string, components: any[]) => {
  const foundValue = components.find((component) => component.types.includes(label))
  if (foundValue) {
    return foundValue.short_name
  }
  return ''
}

export const buildAddress = (placeDetails: any) => {
  const { address_components: addressComponent } = placeDetails
  const resultAddress: AddressT = {
    street: getValueFromGoogleAddressComponent('street_number', addressComponent).concat(
      ` ${getValueFromGoogleAddressComponent('route', addressComponent)}`
    ),
    suburb: getValueFromGoogleAddressComponent('locality', addressComponent),
    state: getValueFromGoogleAddressComponent('administrative_area_level_1', addressComponent),
    postcode: getValueFromGoogleAddressComponent('postal_code', addressComponent),
    country: getValueFromGoogleAddressComponent('country', addressComponent),
  }

  return resultAddress
}
