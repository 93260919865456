import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from '../pages/login'
import Layout from '../components/layout'
import CreateIncident from '../pages/incident/create'
import IncidentDetail from '../pages/incident/detail'
import GuestCheck from '../pages/guest/check'
import Account from '../pages/account'
import Authenticator from '../components/authenticator'

const routes = (
  <Routes>
    <Route path="login" element={<Login />} />
    <Route
      path="/"
      element={
        <Authenticator>
          <Layout />
        </Authenticator>
      }
    >
      <Route
        index
        element={
          <Authenticator>
            <GuestCheck />
          </Authenticator>
        }
      />
      <Route path="incident">
        <Route
          path="create"
          element={
            <Authenticator>
              <CreateIncident />
            </Authenticator>
          }
        />
        <Route
          path=":incidentId"
          element={
            <Authenticator>
              <IncidentDetail />
            </Authenticator>
          }
        />
      </Route>
      <Route
        path="account"
        element={
          <Authenticator>
            <Account />
          </Authenticator>
        }
      />
    </Route>
  </Routes>
)

export default routes
