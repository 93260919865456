import React, { useEffect } from 'react'
import { Col, Row, Typography } from 'antd'
import { useLocation } from 'react-router-dom'
import CreateIncidentForm from './components/form'
import getStyles from './styles'
import { navigateToTop } from '../../../utils'
import { useAppDispatch } from '../../../app/hooks'
import { setStatus } from '../slice'
import StatusE from '../../../types/statusE'

export default function CreateIncident() {
  const styles = getStyles()
  const location = useLocation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    navigateToTop()
  }, [])

  useEffect(() => {
    dispatch(setStatus(StatusE.IDLE))
  }, [location, dispatch])

  return (
    <Row style={styles.root}>
      <Col xs={20} md={18} lg={18} xl={14}>
        <Typography.Title level={1} style={styles.title}>
          Submit an incident report
        </Typography.Title>
        <CreateIncidentForm />
      </Col>
    </Row>
  )
}
