import React from 'react'
import { Card } from 'antd'
import { useAppSelector } from '../../../../app/hooks'
import { UserT } from '../../../../types/userT'
import { SubscriptionUserT } from '../../../../types/subscriptionUserT'
import { formatDate } from '../../../../utils'

export default function Subscriptions() {
  const { user } = useAppSelector((state) => state.account)

  const renderSubscription = (subscription: SubscriptionUserT) => {
    const membership = (user as UserT).active_memberships.find(
      (mb) => mb.id === Number(subscription.membership)
    )
    return (
      <Card key={subscription.id} title={membership?.title}>
        <p>Price: {subscription.price}</p>
        <p>Created at: {formatDate(subscription.created_at)}</p>
        <p>Period: {subscription.period} ({subscription.period_type})</p>
      </Card>
    )
  }

  return user && user.recent_subscriptions ? (
    <>
      {(user as UserT).recent_subscriptions.map((subscription) => renderSubscription(subscription))}
    </>
  ) : (
    <p>You do not have a subscription yet.</p>
  )
}
