import React from 'react'
import { Col, Grid, Image, Row, Tag, Typography, theme } from 'antd'
import { PeopleT } from '../../../../../types/peopleT'
import getStyles from './styles'
import { useAppSelector } from '../../../../../app/hooks'
import { INPUT_DATA_IDENTIFICATION_TYPES } from '../../../../../constants'

export default function IncidentDetailPeople({ people }: { people: PeopleT[] }) {
  const { token } = theme.useToken()
  const screens = Grid.useBreakpoint()
  const styles = getStyles(token, screens)
  const { incidentDetail } = useAppSelector((state) => state.incident)

  const getOffenderImage = (person: PeopleT) => {
    const offenderImage = incidentDetail?.images?.find((image) =>
      (person.images as string[]).find((img) => img === image.id)
    )
    return offenderImage?.uri
  }

  const renderIdentificationType = (type_id?: string) =>
    INPUT_DATA_IDENTIFICATION_TYPES.find((type) => type.value === type_id)?.label || ''

  return (
    <>
      {people.map((person) => (
        <Row
          key={person.given_name}
          style={{
            ...(styles.cardContainer as React.CSSProperties),
            ...(styles.personContainer as React.CSSProperties),
            marginBottom: 16,
          }}
        >
          <Row style={styles.personContainerItem}>
            <Col>
              <Tag style={styles.tagPersonType}>{person.type.toUpperCase()}</Tag>
            </Col>
          </Row>

          <Row style={styles.personContainerItem}>
            <Col xs={24} xl={14}>
              <Row style={styles.cardBodyRow}>
                <Col xs={8} lg={7} xl={6} style={styles.cardBodyColumn as React.CSSProperties}>
                  <Typography.Text type="secondary">LAST NAME</Typography.Text>
                </Col>
                <Col push={screens.xs ? 0 : 1}>
                  <Typography.Text>{person.family_name}</Typography.Text>
                </Col>
              </Row>
              <Row style={styles.cardBodyRow}>
                <Col xs={8} lg={7} xl={6} style={styles.cardBodyColumn as React.CSSProperties}>
                  <Typography.Text type="secondary">FIRST NAME</Typography.Text>
                </Col>
                <Col push={screens.xs ? 0 : 1}>
                  <Typography.Text>{person.given_name}</Typography.Text>
                </Col>
              </Row>
              {person.aliases && person.aliases.length > 0 && (
                <Row style={styles.cardBodyRow}>
                  <Col xs={8} lg={7} xl={6} style={styles.cardBodyColumn as React.CSSProperties}>
                    <Typography.Text type="secondary">ALIAS</Typography.Text>
                  </Col>
                  <Col push={screens.xs ? 0 : 1}>
                    <Typography.Text>{person.aliases.join(', ')}</Typography.Text>
                  </Col>
                </Row>
              )}
              <Row style={styles.cardBodyRow}>
                <Col xs={8} lg={7} xl={6} style={styles.cardBodyColumn as React.CSSProperties}>
                  <Typography.Text type="secondary">DOB</Typography.Text>
                </Col>
                <Col push={screens.xs ? 0 : 1}>
                  <Typography.Text>{person.birth_date}</Typography.Text>
                </Col>
              </Row>
              <Row style={styles.cardBodyRow}>
                <Col xs={8} lg={7} xl={6} style={styles.cardBodyColumn as React.CSSProperties}>
                  <Typography.Text type="secondary">GENDER</Typography.Text>
                </Col>
                <Col push={screens.xs ? 0 : 1}>
                  <Typography.Text>{person.gender}</Typography.Text>
                </Col>
              </Row>
              <Row style={styles.cardBodyRow}>
                <Col xs={8} lg={7} xl={6} style={styles.cardBodyColumn as React.CSSProperties}>
                  <Typography.Text type="secondary">ID TYPE</Typography.Text>
                </Col>
                <Col push={screens.xs ? 0 : 1}>
                  <Typography.Text>
                    {renderIdentificationType(person.identification?.type)}
                  </Typography.Text>
                </Col>
              </Row>
              <Row style={styles.cardBodyRow}>
                <Col xs={8} lg={7} xl={6} style={styles.cardBodyColumn as React.CSSProperties}>
                  <Typography.Text type="secondary">ISSUER</Typography.Text>
                </Col>
                <Col push={screens.xs ? 0 : 1}>
                  <Typography.Text>{person.identification?.issuer}</Typography.Text>
                </Col>
              </Row>
              <Row style={styles.cardBodyRow}>
                <Col xs={8} lg={7} xl={6} style={styles.cardBodyColumn as React.CSSProperties}>
                  <Typography.Text type="secondary">ID NUMBER</Typography.Text>
                </Col>
                <Col push={screens.xs ? 0 : 1}>
                  <Typography.Text>{person.identification?.number}</Typography.Text>
                </Col>
              </Row>
              <Row style={styles.cardBodyRow}>
                <Col xs={8} lg={7} xl={6} style={styles.cardBodyColumn as React.CSSProperties}>
                  <Typography.Text type="secondary">ADDRESS</Typography.Text>
                </Col>
                <Col push={screens.xs ? 0 : 1}>
                  <Typography.Text>{person.address?.street}</Typography.Text>
                </Col>
              </Row>
              <Row style={styles.cardBodyRow}>
                <Col xs={8} lg={7} xl={6} style={styles.cardBodyColumn as React.CSSProperties}>
                  <Typography.Text type="secondary">PHONE NUMBER</Typography.Text>
                </Col>
                <Col push={screens.xs ? 0 : 1}>
                  <Typography.Text>{person.phone_number}</Typography.Text>
                </Col>
              </Row>
            </Col>
            {person.images && person.images.length ? (
              <Col xs={24} xl={10} style={styles.personContainerImages as React.CSSProperties}>
                <Image src={getOffenderImage(person)} style={styles.personImage} />
              </Col>
            ) : null}
          </Row>
        </Row>
      ))}
    </>
  )
}
