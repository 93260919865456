import React from 'react'
import { Typography, theme, Grid, Col, Row, Skeleton, Space } from 'antd'
import { Link } from 'react-router-dom'
import { IncidentT } from '../../../../types/incidentT'
import cardStyles from './styles'
import { formatDate, getDaysAgoFromADate } from '../../../../utils'
import { useAppSelector } from '../../../../app/hooks'
import StatusE from '../../../../types/statusE'
import { toUppercase, isHighlightedValue } from '../utils'

export default function IncidentCard({ incident }: { incident: IncidentT }) {
  const { token } = theme.useToken()
  const screens = Grid.useBreakpoint()
  const styles = cardStyles(token, !screens.sm)
  const { matches } = useAppSelector((state) => state.dashboard)
  const { status } = useAppSelector((state) => state.dashboard)

  const offender = incident.people.find(
    (person, index) => person.type === 'offender' && { ...person, index }
  )
  const associates = incident.people.filter(
    (person, index) => person.type === 'associate' && { ...person, index }
  )
  const matchesFound = matches?.filter((match) => match.parent === incident.id)

  /**
   * 'given_name' and 'family_name' are ignored because they are shown as Card's title
   */
  const renderMatch = () =>
    matchesFound.map((matchFound) => {
      const [, , property]: string[] = matchFound.path.split('.')

      if (['given_name', 'family_name'].includes(property)) return null

      return (
        <div key={`match_${matchFound.path}`} style={styles.matchContainer}>
          <Typography.Text type="secondary" style={styles.bodyContentTitle}>
            {toUppercase(property)}
          </Typography.Text>
          <Typography.Text style={styles.highlight}>{matchFound.value}</Typography.Text>
        </div>
      )
    })

  return (
    <Row style={{ justifyContent: 'center' }}>
      <Col xs={24} xl={18} style={styles.root}>
        <Skeleton active loading={status === StatusE.LOADING} style={styles.skeletonContainer}>
          <Row style={styles.container as React.CSSProperties}>
            {incident.images && incident.images.length > 0 && (
              <Col
                xs={24}
                sm={6}
                style={{
                  backgroundImage: `url(${incident.images[0].uri})`,
                  ...styles.imageContainer,
                }}
              >
                {!screens.sm ? <div style={styles.image} /> : null}
              </Col>
            )}
            <Col xs={24} sm={16} span={16} style={styles.body as React.CSSProperties}>
              <Row style={styles.bodyHeader} gutter={16}>
                <Col>
                  <Link to={`/incident/${incident.id}`} style={{ display: 'flex', gap: 4 }}>
                    <Typography.Title level={4} style={styles.headerTitle} className="cardTitle">
                      {isHighlightedValue(incident, matches, offender?.given_name)}{' '}
                      {isHighlightedValue(incident, matches, offender?.family_name)}
                    </Typography.Title>
                  </Link>
                </Col>
                <Col>
                  {offender?.aliases && offender.aliases.length > 0 && (
                    <Typography.Paragraph style={styles.headerTitle}>
                      ({offender.aliases.join(', ')})
                    </Typography.Paragraph>
                  )}
                </Col>
              </Row>
              <Space style={styles.matchesContainer as React.CSSProperties}>{renderMatch()}</Space>
              <Row style={styles.bodyContent}>
                <Col xs={24} sm={12}>
                  <Row>
                    <Typography.Text type="secondary" style={styles.bodyContentTitle}>
                      INCIDENT DETAILS
                    </Typography.Text>
                  </Row>
                  <Row gutter={8}>
                    <Col>
                      <Typography.Text>
                        {formatDate(incident.datetime, 'DD MMM YYYY')}
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text>
                        ({getDaysAgoFromADate(incident.datetime)} days ago)
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row>
                    <Typography.Text>{incident.location?.suburb}</Typography.Text>
                  </Row>
                </Col>

                {associates.length > 0 && (
                  <Col xs={24} sm={12}>
                    <Typography.Text type="secondary" style={styles.associateTitle}>
                      ASSOCIATES
                    </Typography.Text>
                    {associates.map((associate) => (
                      <Typography key={associate.given_name}>
                        {isHighlightedValue(incident, matches, associate?.given_name)}{' '}
                        {isHighlightedValue(incident, matches, associate?.family_name)}
                      </Typography>
                    ))}
                  </Col>
                )}
              </Row>
              <Row>
                <Link to={`/incident/${incident.id}`}>
                  <Typography.Text
                    type="secondary"
                    style={styles.footerTitle}
                    className="cardFooterLink"
                  >
                    View more details
                  </Typography.Text>
                </Link>
              </Row>
            </Col>
          </Row>
        </Skeleton>
      </Col>
    </Row>
  )
}
