import React, { useEffect } from 'react'
import { Button, Form, Input } from 'antd'
import { PersonalUserT } from '../../../../../types/personalUserT'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import FormPersonalAdress from './formAddress'
import { updateWordpressUser } from '../../../actions'

export default function AccountSettingsForm() {
  const [form] = Form.useForm<PersonalUserT & { full_address: string }>()
  const { user } = useAppSelector((state) => state.account)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (user) {
      const { first_name, last_name, email, address } = user // eslint-disable-line @typescript-eslint/naming-convention
      form.setFieldsValue({ first_name, last_name, email, address })
    }
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = (values: PersonalUserT) => {
    const { first_name, last_name, email, address } = values // eslint-disable-line @typescript-eslint/naming-convention
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (user) {
      dispatch(
        updateWordpressUser(user.id, {
          first_name,
          last_name,
          email,
          address1: address['mepr-address-one'],
          address2: address['mepr-address-two'],
          city: address['mepr-address-city'],
          state: address['mepr-address-state'],
          zip: address['mepr-address-zip'],
          country: address['mepr-address-country'],
        })
      )
    }
  }

  return (
    <Form
      layout="vertical"
      form={form}
      scrollToFirstError
      size="large"
      onFinish={onFinish}
      onFieldsChange={() => {}}
    >
      <Form.Item name="first_name" label="First Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="last_name" label="Last Name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="email" label="Email" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="address" hidden />
      <FormPersonalAdress />
      <Form.Item style={{ display: 'flex', justifyContent: 'center' }}>
        <Button type="primary" htmlType="submit">
          Save changes
        </Button>
      </Form.Item>
    </Form>
  )
}
