import React, { useEffect, useState } from 'react'
import { Col, Form, Input, Row, Select, Typography, theme } from 'antd'
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { BaseOptionType } from 'antd/es/select'
import { buildAddress } from '../../../../utils'
import getStyles from './styles'
import DropdownAddress from './components/dropdown'

export default function DetailPeopleFormAdress({ name }: { name: number }) {
  const { token } = theme.useToken()
  const styles = getStyles(token)
  const [showManualAddress, setShowManualAddress] = useState(false)
  const [placeOptions, setPlaceOptions] = useState<BaseOptionType[]>([])
  const form = Form.useFormInstance()

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading, placesService } =
    useGoogle({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      debounce: 1000,
    })

  useEffect(() => {
    if (placePredictions.length > 0) {
      setPlaceOptions(
        placePredictions.map((place) => ({
          ...place,
          label: place.description,
          value: place.place_id,
        }))
      )
    }
  }, [placePredictions])

  const mapAddress = (address: any) => {
    placesService.getDetails({ placeId: address }, (placeDetails: any) => {
      const resultAddress = buildAddress(placeDetails)
      form.setFieldValue(['people', name, 'address'], resultAddress)
    })
  }

  return showManualAddress ? (
    <>
      <Row justify="space-between" wrap gutter={12}>
        <Col xs={24} sm={12}>
          <Form.Item
            name={[name, 'address', 'street']}
            label={
              <div style={styles.formItemLabelContainer}>
                <Typography.Text>Street Address</Typography.Text>
                <Typography.Link
                  style={styles.defaultLink}
                  onClick={() => setShowManualAddress(false)}
                >
                  Find my address
                </Typography.Link>
              </div>
            }
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name={[name, 'address', 'suburb']} label="Suburb">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between" wrap gutter={12}>
        <Col xs={24} sm={8}>
          <Form.Item name={[name, 'address', 'state']} label="State">
            <Input placeholder="e.g QLD" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item name={[name, 'address', 'postcode']} label="Postcode">
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item name={[name, 'address', 'country']} label="Country">
            <Input placeholder="e.g AU" />
          </Form.Item>
        </Col>
      </Row>
    </>
  ) : (
    <Row>
      <Col xs={24}>
        <Form.Item
          name={[name, 'address', 'full_address']}
          label="Address"
          getValueFromEvent={(address) => mapAddress(address)}
        >
          <Select
            showSearch
            filterOption={false}
            onSearch={(value) => {
              getPlacePredictions({ input: value })
            }}
            options={placeOptions}
            loading={isPlacePredictionsLoading}
            notFoundContent={
              <Row style={styles.formItemSelectNotFoundContainer}>
                <Typography.Paragraph type="secondary">
                  {`Type your address and press 'Enter'`}
                </Typography.Paragraph>
              </Row>
            }
            dropdownRender={(originNode) => (
              <DropdownAddress
                renderOriginNode={originNode}
                renderSetShowManualAddress={setShowManualAddress}
              />
            )}
          />
        </Form.Item>
      </Col>
    </Row>
  )
}
