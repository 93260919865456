import { createSlice } from '@reduxjs/toolkit'
import StatusE from '../../types/statusE'
import initialDashboardState from './state'

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialDashboardState,
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setError: (state, action) => {
      state.status = StatusE.IDLE
      state.error = action.payload
    },
    setIncidents: (state, action) => {
      state.incidents = action.payload
      state.status = StatusE.IDLE
      state.isFirstRender = false
    },
    setQuery: (state, action) => {
      state.query = action.payload
    },
    setMatches: (state, action) => {
      state.matches = action.payload
    },
  },
})

export const { setStatus, setError, setIncidents, setQuery, setMatches } = dashboardSlice.actions

export default dashboardSlice.reducer
