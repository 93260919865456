import StatusE from '../../types/statusE'
import { DashboardState } from './stateI'

const initialDashboardState: DashboardState = {
  status: StatusE.IDLE,
  incidents: [],
  query: '',
  isFirstRender: true,
  matches: [],
}

export default initialDashboardState
