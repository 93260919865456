import { ThemeConfig } from 'antd'

const theme: ThemeConfig = {
  token: {
    colorPrimary: '#ff4922',
    colorBgContainer: '#ffffff',
    colorBgLayout: '#ffffff',
    colorBgSpotlight: '#333',
    fontFamily: `'DM Sans', sans-serif`,
  },
  components: {
    Input: {
      borderRadius: 4,
      borderRadiusLG: 4,
    },
    DatePicker: {
      borderRadius: 4,
      borderRadiusLG: 4,
    },
    Select: {
      borderRadius: 4,
      borderRadiusLG: 4,
    },
  },
}

export default theme
