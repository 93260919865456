import React from 'react'
import { Button, Col, Form, Row, Tag, theme, Typography } from 'antd'
import DetailPeopleFormSection from './components/detail'
import getStyles from './styles'

export default function IncidentPeopleFormSection({
  isAdvancedSearch,
}: {
  isAdvancedSearch?: boolean
}) {
  const { token } = theme.useToken()
  const styles = getStyles(token)
  const currentPeople = Form.useWatch('people')

  return (
    <Form.Item
      name="people"
      label={
        <Row style={styles.formItemRootLabelContainer}>
          <Col>
            <Typography.Title level={5}>People</Typography.Title>
          </Col>
          <Col>
            {currentPeople ? (
              <Tag style={styles.defaultTag as React.CSSProperties}>{currentPeople.length}</Tag>
            ) : null}
          </Col>
        </Row>
      }
      style={styles.root}
    >
      <Form.List name="people" initialValue={[{ type: 'offender' }]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <DetailPeopleFormSection
                key={key}
                index={key}
                name={name}
                remove={remove}
                restField={restField}
                isAdvancedSearch={isAdvancedSearch}
              />
            ))}
            <Form.Item style={styles.secondaryButtonContainer}>
              <Button
                className="secondary-button-custom"
                onClick={() => add()}
                style={styles.secondaryButton}
              >
                Add Person
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form.Item>
  )
}

IncidentPeopleFormSection.defaultProps = {
  isAdvancedSearch: false,
}
