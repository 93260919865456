import { store } from '../../app/store'
import APIClient from '../../apiClient'
import StatusE from '../../types/statusE'
import { setStatus, setError, setResponse, setIncidentDetail } from './slice'
import { IncidentT } from '../../types/incidentT'
import { IncidentResponseT } from '../../types/incidentResponseT'
import { ErrorT } from '../../types/errorT'

const apiClient = new APIClient()
const apiInstance = apiClient.getInstance()

export const createIncident = (incident: IncidentT) => (dispatch: typeof store.dispatch) => {
  dispatch(setStatus(StatusE.LOADING))
  apiInstance
    .post<IncidentResponseT>(`/incident`, incident, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      dispatch(setResponse(response.data))
    })
    .catch((error: ErrorT) => {
      dispatch(setError(error))
    })
}

export const getIncidentDetail = (incidentId: string) => (dispatch: typeof store.dispatch) => {
  dispatch(setStatus(StatusE.LOADING))
  apiInstance
    .get<IncidentResponseT>(`/incident/${incidentId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      dispatch(setIncidentDetail(response.data.data))
    })
    .catch((error: ErrorT) => {
      dispatch(setError(error))
    })
}
