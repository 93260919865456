const drawerStyles = () => ({
  root: {
    width: 290,
  },
  menuItemBottom: {
    fontWeight: 600,
    fontSize: 16,
  },
})

export default drawerStyles
