import axios, { InternalAxiosRequestConfig } from 'axios'
import { store } from '../app/store'
import { setIsLoggedIn } from '../pages/login/slice'
import * as Constants from '../constants'
import Token from '../token'

export default class APIClient {
  private requiresToken

  private instance

  constructor(requiresToken = true, headers = {}, baseURL = process.env.REACT_APP_API_URL) {
    this.requiresToken = requiresToken

    this.instance = axios.create({
      baseURL,
      headers: { ...headers },
    })

    this.instance.interceptors.request.use(async (config) => this.addTokenRequest(config))

    this.instance.interceptors.response.use(
      (response) => {
        const typedResponse = {
          ...response,
          data: APIClient.dataParser(response.data),
        }
        return typedResponse
      },
      (error) => Promise.reject(error)
    )
  }

  private async addTokenRequest(config: InternalAxiosRequestConfig) {
    if (this.requiresToken) {
      if (Token.isValid(Constants.GTOB_TOKEN_LONG_EXPIRATION_IDENTIFIER)) {
        const tokenValue = await Token.getValue()
        if (tokenValue) {
          config.headers.Authorization = `Bearer ${tokenValue}`
          return config
        }
      } else {
        const abortController = new AbortController()
        localStorage.removeItem(Constants.GTOB_TOKEN_IDENTIFIER)
        localStorage.removeItem(Constants.GTOB_TOKEN_EXPIRATION_IDENTIFIER)
        localStorage.removeItem(Constants.GTOB_TOKEN_LONG_EXPIRATION_IDENTIFIER)
        store.dispatch(setIsLoggedIn(false))
        abortController.abort()
        const cfg = {
          ...config,
          signal: abortController.signal,
        }
        return cfg
      }
    }
    return config
  }

  private static dataParser(data: string | object) {
    return typeof data === 'string' ? JSON.parse(data) : data
  }

  getInstance() {
    return this.instance
  }
}
