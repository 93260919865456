import { store } from '../../app/store'
import APIClient from '../../apiClient'
import { IncidentResponseT } from '../../types/incidentResponseT'
import StatusE from '../../types/statusE'
import { setError, setIncidents, setMatches, setStatus } from './slice'
import { ErrorT } from '../../types/errorT'
import { AdvancedSearchFormT } from './check/components/advancedSearch'

const apiClient = new APIClient()
const apiInstance = apiClient.getInstance()

export const getIncidents = (query: string) => (dispatch: typeof store.dispatch) => {
  dispatch(setStatus(StatusE.LOADING))

  apiInstance
    .get<IncidentResponseT>(
      `/guest-check/search?query=${query}`
    )
    .then((response) => {
      if (response.data) {
        dispatch(setIncidents(response.data.data))
        dispatch(setMatches(response.data.matches))
      }
    })
    .catch((error: ErrorT) => {
      dispatch(setError(error))
    })
}

export const getIncidentById = (incidentId: number) => incidentId

export const getAdvancedIncidents =
  (values: AdvancedSearchFormT) => (dispatch: typeof store.dispatch) => {
    dispatch(setStatus(StatusE.LOADING))

    apiInstance
      .post<IncidentResponseT>('/guest-check/query', values)
      .then((response) => {
        if (response.data) {
          dispatch(setIncidents(response.data.data))
          dispatch(setMatches(response.data.matches))
        }
      })
      .catch((error: ErrorT) => {
        dispatch(setError(error))
      })
  }
