import { createSlice } from '@reduxjs/toolkit'
import initialDrawerState from './state'

const drawerSlice = createSlice({
  name: 'drawer',
  initialState: initialDrawerState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload
    },
  },
})

export const { setOpen } = drawerSlice.actions

export default drawerSlice.reducer
