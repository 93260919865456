import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import routes from './app/router'
import theme from './theme'

export default function App() {
  return (
    <ConfigProvider theme={theme}>
      <BrowserRouter>{routes}</BrowserRouter>
    </ConfigProvider>
  )
}
