import { GlobalToken } from 'antd'

const styles = (theme: GlobalToken) => ({
  root: {
    borderTop: 1,
    borderColor: theme?.colorFill,
    borderStyle: 'solid',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  panelIcon: {
    size: 20,
    color: theme?.colorFill,
  },
  collpasePanelheader: {
    alignItems: 'start',
  },
  collapsePanelExtraButton: {
    backgroundColor: theme?.colorFill,
    minWidth: 19,
    width: 19,
    height: 19,
  },
  collapsePanelExtraButtonIcon: {
    color: theme?.colorWhite,
    size: 14,
  },
  formItemRadioPanelContainer: {
    width: '100%',
    height: 'auto',
    borderRadius: 4,
    padding: '7px 11px',
  },
  formItemRadioPanel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formItemSelectNotFoundContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  formItemDatePickerContainer: { width: '100%' },
  tagContainer: {
    border: 0,
    backgroundColor: theme.colorFill,
    display: 'flex',
    alignItems: 'center',
  },
  tagIcon: {
    fontSize: 14,
    color: theme.colorWhite,
    marginTop: 6,
  },
})

export default styles
